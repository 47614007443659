import { environment } from '../../environments/environment';

class ApiUrl {
  static paths = {
    searchTransaction: 'SearchTransaction',
    newLinkOperation: 'newlinkoperation',
    signPdf: 'sign-pdf',
  };
}

export class UrlFactory {
  private static apiUrl: string = environment.API_URL;

  static postNewLinkOperation(): string {
    return `${this.apiUrl}/${ApiUrl.paths.newLinkOperation}/`;
  }

  static postSearchTransaction(): string {
    return `${this.apiUrl}/${ApiUrl.paths.searchTransaction}/`;
  }

  static postSignContract(): string {
    return `${this.apiUrl}/${ApiUrl.paths.signPdf}/`;
  }
}
