import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/angular/standalone';
import { PDFDocumentProxy, PdfViewerModule } from 'ng2-pdf-viewer';
import { StoreService } from '../services/store.service';

@Component({
  selector: 'app-read-contract',
  templateUrl: './read-contract.component.html',
  styleUrls: ['./read-contract.component.scss'],
  standalone: true,
  imports: [
    IonGrid,
    IonToolbar,
    IonImg,
    IonButton,
    IonCheckbox,
    IonText,
    IonRow,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ReadContractComponent implements OnInit {
  pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';

  contractForm: FormGroup = this._fb.group({});
  hasScrolledToBottom = false;
  pdfPageNumber = 1;
  private pdfLastPageNumber = 3;

  private _loader: HTMLIonLoadingElement | null = null;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _storeService: StoreService,
    private _loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    this._showLoading();
    this._formBuilder();
    this._storeService.searchTransactionResponse$.subscribe((response) => {
      if (response?.contrato) {
        this.loadPdfBase64(response.contrato);
      }
    });
  }

  onSubmit(): void {}

  navigateToNextStep(): void {
    this._router.navigate(['pre-scan']);
  }

  onLoadComplete(pdf: PDFDocumentProxy) {
    const pdfEl = document.querySelector('.ng2-pdf-viewer-container');

    if (pdfEl) {
      pdfEl.addEventListener('scroll', () => {
        if (this.hasScrolledToBottom) {
          return;
        }
        if (this.pdfPageNumber == this.pdfLastPageNumber) {
          this.hasScrolledToBottom = true;
          this.contractForm.get('terms')?.enable();
        }
      });
      this._loader?.dismiss();
    }
  }

  loadPdfBase64(pdfBase64: string) {
    const base64Data = pdfBase64.startsWith('data:application/pdf;base64,')
      ? pdfBase64
      : `data:application/pdf;base64,${pdfBase64}`;
    const binaryData = atob(base64Data.split(',')[1]); // Decodifica Base64 a binario
    const len = binaryData.length;
    const arrayBuffer = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    this.pdfSrc = URL.createObjectURL(blob);
  }

  private _formBuilder(): void {
    this.contractForm = this._fb.group({
      terms: [false, [Validators.required, Validators.requiredTrue]],
    });
    this.contractForm.get('terms')?.disable();
  }

  private async _showLoading() {
    const loading: HTMLIonLoadingElement = await this._loadingCtrl.create({
      message: 'Espere por favor',
    });
    this._loader = loading;
    loading.present();
  }
}
