<ion-toolbar class="w-full" color="ligth">
  <div class="flex flex-row items-center ml-4 my-2">
    <ion-img class="w-10 h-10 mr-2" src="../../../assets/generic-logo.svg">
    </ion-img>
    <ion-text class="my-1">
      <p class="font-bold">Nombre Empresa</p>
    </ion-text>
  </div>
</ion-toolbar>
<div class="ion-text-center h-full">
  <ion-grid class="w-11/12 h-full">
    <form
      action=""
      [formGroup]="contractForm"
      (ngSubmit)="onSubmit()"
      class="h-full"
    >
      <div class="flex flex-col items-center justify-around h-full">
        <ion-row class="flex flex-col items-center justify-center">
          <pdf-viewer
            [src]="pdfSrc"
            [render-text]="true"
            [original-size]="false"
            [(page)]="pdfPageNumber"
            (after-load-complete)="onLoadComplete($event)"
            style="width: 400px; height: 500px"
          >
          </pdf-viewer>
          <ion-checkbox
            labelPlacement="end"
            formControlName="terms"
            class="ion-padding"
            >He leído y declaro mi conformidad</ion-checkbox
          >
          <section class="flex flex-col items-center">
            <ion-button
              (click)="navigateToNextStep()"
              expand="block"
              class="w-80 h-10"
              shape="round"
              type="submit"
              [disabled]="contractForm.get('terms')?.value !== true"
            >
              <span class="text-lg">Continuar </span>
            </ion-button>
          </section>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <section class="flex flex-row justify-between items-center gap-1">
            <ion-text>
              <span>Powered by</span>
            </ion-text>
            <ion-img
              class="footer-logo w-9 h-9"
              src="../../../assets/obd-logo.svg"
            >
            </ion-img>
            <ion-text class="font-bold leading-5 text-base">
              <span>Onboarding Digital</span>
            </ion-text>
          </section>
        </ion-row>
      </div>
    </form>
  </ion-grid>
</div>
