import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObdService {
  httpClient = inject(HttpClient);

  clientDni$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  clientPhoneNumber$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  private GETLINKURL: string = `https://obd.entreconsultas.com/api/newlinkoperation/`;

  constructor() {}

  getObdLink(idOperacion: number = 1): Observable<any> {
    const body = {
      dni: this.clientDni$.value ? this.clientDni$.value : 40068725,
      id_op_client: idOperacion,
    };
    return this.httpClient.post<any>(this.GETLINKURL, body);
  }
}
