import { Component, OnInit } from '@angular/core';
import { addIcons } from 'ionicons';
import {
  IonRow,
  IonText,
  IonGrid,
  IonButton,
  IonImg,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonToolbar,
} from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-product-overview',
  standalone: true,
  imports: [
    IonToolbar,
    IonCheckbox,
    IonItem,
    IonList,
    IonCardContent,
    IonCard,
    IonImg,
    IonButton,
    IonGrid,
    IonText,
    IonRow,
    IonSelect,
    IonSelectOption,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss'],
})
export class ProductOverviewComponent implements OnInit {
  cuotasForm: FormGroup = this._fb.group({});

  cuotas: { id: number; description: string }[] = [
    { id: 1, description: '12 cuotas' },
    { id: 2, description: '18 cuotas' },
    { id: 3, description: '24 cuotas' },
    { id: 4, description: '36 cuotas' },
  ];
  constructor(private _router: Router, private _fb: FormBuilder) {}

  ngOnInit() {
    this._formBuilder();
  }

  onSubmit(): void {
    if (this.cuotasForm.invalid) {
      this.cuotasForm.markAllAsTouched();
      return;
    }
    this.navigateToNextStep();
  }

  navigateToNextStep(): void {
    this._router.navigate(['verification']);
  }

  private _formBuilder(): void {
    this.cuotasForm = this._fb.group({
      cuotasId: ['', [Validators.required]],
      terms: [false, [Validators.required, Validators.requiredTrue]],
    });
  }
}
