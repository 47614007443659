<ion-toolbar class="w-full" color="ligth">
  <div class="flex flex-row items-center ml-4 my-2">
    <ion-img class="w-10 h-10 mr-2" src="../../../assets/generic-logo.svg">
    </ion-img>
    <ion-text class="my-1">
      <p class="font-bold">Nombre Empresa</p>
    </ion-text>
  </div>
</ion-toolbar>
<div class="ion-text-center h-full">
  <ion-grid class="h-full">
    <div class="flex flex-col items-center justify-around h-full">
      <ion-row class="h-5/6 w-full">
        <ion-card class="w-full">
          <ion-card-content class="flex flex-col justify-between h-full">
            <div
              class="chat-card-container flex flex-col gap-5 overflow-scroll"
              #messageContainer
            >
              @for (message of messages; track $index) {
              <app-chat-message
                [messageSendedByUser]="message.messageSendedByUser"
                [message]="message.messageBody"
              ></app-chat-message>
              }
            </div>

            <div class="input">
              <ion-item-divider class="rounded-sm">
                <form
                  [formGroup]="newMessageForm"
                  class="flex flex-row items-center justify-between w-full border-slate-300 border rounded-xl"
                >
                  <ion-input
                    aria-label="Email"
                    formControlName="message"
                    type="number"
                    (keyup.enter)="submitMessage()"
                  ></ion-input>

                  <ion-icon
                    name="send"
                    (click)="submitMessage()"
                    class="mr-5"
                  ></ion-icon>
                </form>
              </ion-item-divider>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-row>

      <ion-row class="ion-justify-content-center ion-align-items-center">
        <section class="flex flex-row justify-between items-center gap-1">
          <ion-text>
            <span>Powered by</span>
          </ion-text>
          <ion-img
            class="footer-logo w-9 h-9"
            src="../../../assets/obd-logo.svg"
          >
          </ion-img>
          <ion-text class="font-bold leading-5 text-base">
            <span>Onboarding Digital</span>
          </ion-text>
        </section>
      </ion-row>
    </div>
  </ion-grid>
</div>
