import { Component, OnInit } from '@angular/core';
import {
  IonImg,
  IonSpinner,
  IonText,
  IonToolbar,
} from '@ionic/angular/standalone';
import {
  AnimationLoader,
  LottieComponent,
  provideLottieOptions,
} from 'ngx-lottie';
import { ObdService } from '../services/obd.service';
import { StoreService } from '../services/store.service';

@Component({
  selector: 'app-exit-screen',
  standalone: true,
  imports: [IonToolbar, IonSpinner, IonImg, IonText, LottieComponent],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
  templateUrl: './exit-screen.component.html',
  styleUrls: ['./exit-screen.component.scss'],
})
export class ExitScreenComponent implements OnInit {
  animation = {
    path: '../../../assets/animations/success.json',
    loop: false,
  };

  private _host: string = 'pwa.obd.com.ar';

  constructor(
    private _storeService: StoreService,
    private _obdService: ObdService
  ) {}

  ngOnInit() {
    this._storeService.transactionId$.subscribe((uuid) => {
      if (uuid) {
        const URL = `https://${this._host}/start/${uuid}`;
        setTimeout(() => {
          this.goToLink(URL);
        }, 5000);
      } else {
        this._obdService.getObdLink().subscribe((resp) => {
          const URL = `https://${this._host}/start/${resp.transaccion}`;
          setTimeout(() => {
            this.goToLink(URL);
          }, 5000);
        });
      }
    });
  }

  goToLink(url: string) {
    window.open(url);
  }
}
