<ion-toolbar class="w-full" color="ligth">
  <div class="flex flex-row items-center ml-4 my-2">
    <ion-img class="w-10 h-10 mr-2" src="../../../assets/generic-logo.svg">
    </ion-img>
    <ion-text class="my-1">
      <p class="font-bold">Nombre Empresa</p>
    </ion-text>
  </div>
</ion-toolbar>
<div class="ion-text-center h-full">
  <ion-grid class="w-11/12 h-full">
    <form
      action=""
      [formGroup]="verificationCodeForm"
      (ngSubmit)="onSubmit()"
      class="h-full"
    >
      <div class="flex flex-col items-center justify-around h-full">
        <ion-row class="ion-padding">
          <p class="mx-5">
            <ion-text>
              Recibirás un codigo de 4 dígitos, para continuar ingresalo a
              continuación...
            </ion-text>
          </p>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <ion-input
            id="custom-input"
            label="Ingresa el codigo de verificación"
            formControlName="code"
            type="number"
            labelPlacement="floating"
            [counter]="true"
            class="mt-8"
            maxlength="4"
            [counterFormatter]="customCounterFormatter"
          ></ion-input>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <p class="ion-padding w-3/4">
            <ion-text class=""> ¿No recibiste tu codigo? </ion-text>
            <br />
            <span class="pt-5"
              ><a (click)="showLoading()">Clickeá para reenviar.</a></span
            >
          </p>

          <section class="flex flex-col items-center ion-padding">
            <ion-button
              expand="block"
              class="w-80 h-10"
              shape="round"
              type="submit"
              [disabled]="verificationCodeForm.invalid"
            >
              <span class="text-lg">Continuar </span>
            </ion-button>
          </section>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <section class="flex flex-row justify-between items-center gap-1">
            <ion-text>
              <span>Powered by</span>
            </ion-text>
            <ion-img
              class="footer-logo w-9 h-9"
              src="../../../assets/obd-logo.svg"
            >
            </ion-img>
            <ion-text class="font-bold leading-5 text-base">
              <span>Onboarding Digital</span>
            </ion-text>
          </section>
        </ion-row>
      </div>
    </form>
  </ion-grid>
</div>
