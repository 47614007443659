import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISearchTransactionResponse } from '../interfaces/searchTransactionResponse';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  searchTransactionResponse$: BehaviorSubject<ISearchTransactionResponse | null> =
    new BehaviorSubject<ISearchTransactionResponse | null>(null);

  transactionId$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor() {}
}
