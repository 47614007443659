import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItemDivider,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { send } from 'ionicons/icons';
import { ObdService } from '../services/obd.service';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    IonToolbar,
    IonIcon,
    IonItemDivider,
    IonInput,
    IonImg,
    IonText,
    IonRow,
    IonGrid,
    IonCardContent,
    IonCard,
    ChatMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  newMessageForm: FormGroup = this._fb.group({});
  messages: { messageSendedByUser: boolean; messageBody: string }[] = [];

  //Este contador sirve para identificar cuantos mensajes CORRECTOS envio el usuario.
  userMessagesCounter: number = 0;
  systemMessagesCounter: number = 0;

  //Mensajes estaticos
  systemMessage1 = `¡Hola! ¿Te gustaría solicitar un préstamo?\n1 - Sí, me interesa!\n2 - No, gracias`;
  systemMessage1Error = `¡Ups, tuvimos un problema!\nPor favor ingresa unicamente una de las opciones indicadas: \n1 - Sí, me interesa!\n2 - No, gracias`;
  systemMessage1Exit = `Gracias, puede volver a ingresar a nuestro sitio en otro momento.`;

  systemMessage2 = `Para comenzar, ingresá tu número de teléfono incluyendo codigo de area.:\n Segui este formato:\n 112223333`;
  systemMessage2Error = `¡Ups, tuvimos un problema!\nPor favor ingresá tu número de teléfono incluyendo codigo de area.:\n Segui este formato:\n 112223333`;

  systemMessage3 = `Por favor ingresá tu número de DNI. Ingresalo sin puntos, espacios ni guiones,  seguí este formato:\n22333444`;
  systemMessage3Error = `¡Ups, tuvimos un problema!\nPor favor ingresá tu número de DNI. Ingresalo sin puntos, espacios ni guiones,  seguí este formato:\n22333444`;

  systemMessage4 = `Excelente, danos un momento para comprobar el mejor préstamo que podemos ofrecerte...`;
  systemMessage4Error = `¡Ups, tuvimos un problema! Por favor intentalo más tarde`;

  systemMessage5 = `<a [routerLink]=['overview']>Ver la oferta</a>`;

  @ViewChild('messageContainer', { static: false })
  messageContainer!: ElementRef;

  constructor(
    private _fb: FormBuilder,
    private _loadingCtrl: LoadingController,
    private _router: Router,
    private elementRef: ElementRef,
    private _obdService: ObdService
  ) {
    addIcons({ send });
  }

  ngOnInit() {
    this._formBuilder();
    this._insertSystemMessage(this.systemMessage1);
  }

  async submitMessage(): Promise<void> {
    if (this.newMessageForm.invalid) {
      this.newMessageForm.markAllAsTouched();
      return;
    }
    const message: number = this.newMessageForm.get('message')?.value;
    if (this._validateUserMessage(message.toString())) {
      this._insertUserMessage(message.toString());
      //Si es el primer mensaje y el usuario indica que no esta interesado:
      if (this.userMessagesCounter === 0 && message.toString() == '2') {
        this._userNotInterested();
        return;
      }

      //Indico que se inserto un nuevo mensaje por parte del usuario
      this.userMessagesCounter++;

      //Pido el proximo mensaje del sistema
      const sucessMessage = this._getSystemSucessMessage();
      this._insertSystemMessage(sucessMessage);

      if (this.systemMessagesCounter >= 3) {
        if (this.systemMessagesCounter == 3) {
          this._obdService.clientPhoneNumber$.next(message.toString());
        }
        if (this.systemMessagesCounter == 4) {
          this._obdService.clientDni$.next(message.toString());
          setTimeout(() => {
            this._router.navigate(['overview']);
          }, 4000);
        }
      }
    } else {
      this._insertUserMessage(message.toString());
      const errorMessage = this._getSystemErrorMessage();
      this._insertSystemMessage(errorMessage);
      this.systemMessagesCounter--;
    }

    setTimeout(() => {
      if (this.messageContainer && this.messageContainer.nativeElement) {
        this.messageContainer.nativeElement.scrollTop =
          this.messageContainer.nativeElement.scrollHeight;
      }
    }, 10);
  }

  private _userNotInterested(): void {
    this._insertSystemMessage(this.systemMessage1Exit);
    this.newMessageForm.get('message')?.setValue('');
    this.newMessageForm.disable();
  }

  private _validateUserMessage(currentMessageToEvaluate: string): boolean {
    if (currentMessageToEvaluate == '') return false;
    switch (this.userMessagesCounter) {
      case 0:
        if (
          currentMessageToEvaluate == '1' ||
          currentMessageToEvaluate == '2'
        ) {
          return true;
        }
        return false;

      default:
        if (
          currentMessageToEvaluate.length >= 7 &&
          currentMessageToEvaluate.length <= 15
        ) {
          return true;
        }
        return false;
    }
  }

  private _insertSystemMessage(message: string): void {
    this.systemMessagesCounter++;
    const formattedMessage = message.replace(/\n/g, '<br>');
    this.messages.push({
      messageSendedByUser: false,
      messageBody: formattedMessage,
    });
    setTimeout(() => {
      if (this.messageContainer && this.messageContainer.nativeElement) {
        this.messageContainer.nativeElement.scrollTop =
          this.messageContainer.nativeElement.scrollHeight;
      }
    }, 300);
  }

  private _getSystemErrorMessage(): string {
    switch (this.userMessagesCounter) {
      case 0:
        return this.systemMessage1Error;
      case 1:
        return this.systemMessage2Error;
      case 2:
        return this.systemMessage3Error;
      default:
        return this.systemMessage4Error;
    }
  }

  private _getSystemSucessMessage(): string {
    if (this.userMessagesCounter > 2) {
    }
    switch (this.userMessagesCounter) {
      case 0:
        return this.systemMessage1;
      case 1:
        return this.systemMessage2;
      case 2:
        return this.systemMessage3;
      case 3:
        return this.systemMessage4;
      default:
        this.showLoading();
        return this.systemMessage5;
    }
  }

  private _insertUserMessage(message: string): void {
    this.messages.push({
      messageSendedByUser: true,
      messageBody: message,
    });
    this.newMessageForm.get('message')?.setValue('');
  }

  async showLoading() {
    const loading: HTMLIonLoadingElement = await this._loadingCtrl.create({
      message: 'Estamos verificando tu linea de crédito...',
      duration: 4000,
    });
    loading.present();

    await setTimeout(() => {
      this._redirectToNextStep();
    }, 4000);
  }

  private _redirectToNextStep(): void {
    this._router.navigate(['overview']);
  }

  private _formBuilder(): void {
    this.newMessageForm = this._fb.group({
      message: ['', [Validators.minLength(1), Validators.required]],
    });
  }
}
