import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import {
  IonToolbar,
  IonHeader,
  IonText,
  IonImg,
  IonList,
  IonItem,
  IonRow,
  IonCheckbox,
  IonButton,
  IonCard,
  IonGrid,
  IonCardContent,
  IonInput,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-phone-code-verification',
  standalone: true,
  imports: [
    IonInput,
    IonCardContent,
    IonGrid,
    IonCard,
    IonButton,
    IonCheckbox,
    IonRow,
    IonItem,
    IonList,
    IonImg,
    IonText,
    IonHeader,
    IonToolbar,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './phone-code-verification.component.html',
  styleUrls: ['./phone-code-verification.component.scss'],
})
export class PhoneCodeVerificationComponent implements OnInit {
  verificationCodeForm: FormGroup = this._fb.group({});

  private _isSendCodeDissabled: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private loadingCtrl: LoadingController,
    private _router: Router
  ) {}

  ngOnInit() {
    this._formBuilder();
  }

  private _formBuilder(): void {
    this.verificationCodeForm = this._fb.group({
      code: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  onSubmit(): void {
    if (this.verificationCodeForm.invalid) {
      this.verificationCodeForm.markAllAsTouched();
      return;
    }
    this._navigateToNextStep();
  }

  private _navigateToNextStep(): void {
    this._router.navigate(['contract']);
  }

  async showLoading() {
    if (this._isSendCodeDissabled) return;
    const loading = await this.loadingCtrl.create({
      message:
        'En los proximos minutos, recibiras nuevamente un codigo para realizar la verificación',
      duration: 3000,
    });
    loading.present();
    this._isSendCodeDissabled = true;
  }

  customCounterFormatter(inputLength: number, maxLength: number) {
    return `${maxLength - inputLength} carácteres restantes`;
  }
}
