import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IonButton,
  IonGrid,
  IonImg,
  IonRow,
  IonText,
} from '@ionic/angular/standalone';
import { ObdService } from '../services/obd.service';
import { StoreService } from '../services/store.service';
import { take } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { ISearchTransactionResponse } from '../interfaces/searchTransactionResponse';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [IonButton, IonGrid, IonRow, IonText, IonImg],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  urlId: string | null = '';
  isButtonBlocked: boolean = false;
  showUrlError: boolean = false;
  verificationAlreadyRequested: boolean = false;

  loading: boolean = true;

  //Este boolean sirve para realizar un toggle entre la información de inicio dependiendo para el tramite que entra
  showFirmaDigitalHome: boolean = true;

  constructor(
    private _router: Router,
    private _obdService: ObdService,
    private _storeService: StoreService,
    private _actRoute: ActivatedRoute,
    private _loadingCtrl: LoadingController
  ) {}

  ngOnInit(): void {
    this._storeService.transactionId$.pipe(take(1)).subscribe((currentUUID) => {
      if (!currentUUID || currentUUID == '') {
        if (!currentUUID || !this._checkIsAValidUUID(currentUUID)) {
          this._actRoute.params.subscribe((params) => {
            this.showUrlError = false;
            this.urlId = params['id'];
            if (!this.urlId || !this._checkIsAValidUUID(this.urlId)) {
              // this.showUrlError = true;
              this.showFirmaDigitalHome = false;
              // this.isButtonBlocked = true;
              this.loading = false;
              return;
            } else {
              this._storeService.transactionId$.next(this.urlId);
              this._showLoading();
            }
          });
        }
      } else {
        this.urlId = currentUUID;
        this._showLoading();
      }
    });
  }

  navigateToNextStep(): void {
    if (this.showFirmaDigitalHome) {
      this._router.navigate(['contract']);
      return;
    }
    this._router.navigate(['chat']);
  }

  private async _showLoading() {
    if (this.verificationAlreadyRequested) return;

    const loading: HTMLIonLoadingElement = await this._loadingCtrl.create({
      message: 'Securizando comunicación',
    });
    loading.present();
    this._securalizeData(loading);
  }

  private _securalizeData(loading: HTMLIonLoadingElement): void {
    if (!this.urlId) return;
    this._obdService.validateUrlId(this.urlId).subscribe({
      next: (response) => {
        if (response.status) {
          localStorage.setItem('uuid', this.urlId!);
          this.verificationAlreadyRequested = true;
          loading.dismiss();
          this.loading = false;
          this._storeService.searchTransactionResponse$.next(response);
          this._toggleBienvenida(response);
        } else {
          this.showUrlError = true;
          // this.isButtonBlocked = true;
          loading.dismiss();
          this.loading = false;
        }
      },
      error: (error: any) => {
        console.log(error);
        this.showUrlError = true;
        // this.isButtonBlocked = true;
        loading.dismiss();
        this.loading = false;
      },
    });
  }

  private _checkIsAValidUUID(value: string): boolean {
    const uuidRegex = new RegExp(
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    );
    return uuidRegex.test(value);
  }

  private _toggleBienvenida(response: ISearchTransactionResponse): void {
    if (response.firma && response.contrato) {
      this.showFirmaDigitalHome = true;
      return;
    }
  }
}
