import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UrlFactory } from '../utils/url-factory';
import { ISearchTransactionResponse } from '../interfaces/searchTransactionResponse';
import { ISignPdfBody } from '../interfaces/signPdf';

@Injectable({
  providedIn: 'root',
})
export class ObdService {
  httpClient = inject(HttpClient);

  clientDni$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  clientPhoneNumber$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor(private _http: HttpClient) {}

  getObdLink(idOperacion: number = 1): Observable<any> {
    const url = UrlFactory.postNewLinkOperation();
    const body = {
      dni: this.clientDni$.value ? this.clientDni$.value : 40068725,
      id_op_client: idOperacion,
      telefono: this.clientPhoneNumber$.value
        ? this.clientPhoneNumber$.value
        : '',
      firma: false,
    };
    return this.httpClient.post<any>(url, body);
  }

  signPdf(body: ISignPdfBody): Observable<any> {
    const url = UrlFactory.postSignContract();
    return this.httpClient.post<any>(url, body);
  }

  validateUrlId(urlId: string): Observable<ISearchTransactionResponse> {
    const url = UrlFactory.postSearchTransaction();
    const body: { uuid: string } = {
      uuid: urlId,
    };
    return this._http.post<ISearchTransactionResponse>(url, body);
  }
}
